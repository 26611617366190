import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { PropsWithChildren, SyntheticEvent } from 'react'
import clsx from 'clsx'

import { IconArrow } from 'icons'

import { IBanner } from './types'

const Banner = ({
  children,
  href,
  disable,
  buttonText,
  buttonClass,
  className,
  onClick
}: PropsWithChildren<IBanner>) => {
  const { t } = useTranslation(['actions'])

  return (
    <Link
      href={href}
      className={clsx(
        'animated-svg group flex flex-col items-center justify-center border-2 border-black bg-white px-5 py-5 transition-all sm:flex-row',
        disable &&
          'cursor-not-allowed hover:border-gray-500 hover:bg-gray-200 ',
        !disable &&
          'hover:border-red-550 hover:bg-red-550 hover:text-white active:border-red-550 active:bg-red-550 active:text-white ',
        className
      )}
      onClick={(e: SyntheticEvent) => {
        if (disable) {
          e.preventDefault()
        }
        onClick?.()
      }}
    >
      <div className="flex-1">{children}</div>
      <div
        className={clsx(
          'animated-svg ml-auto mr-0 mt-2 flex items-center font-semibold uppercase group-hover:text-current',
          buttonClass
        )}
      >
        <span className="mx-5">
          {buttonText || t('create', { ns: 'actions' })}
        </span>
        <IconArrow />
      </div>
    </Link>
  )
}

export default Banner

import { asLink, isFilled } from '@prismicio/helpers'
import { ContentRelationshipField } from '@prismicio/types'

import { Banner } from 'components/Banner'
import { Bounded } from 'components/Bounded'

import { AdvertisementBannerDocument } from 'types/prismic'
import { linkResolver } from 'env/prismicio'

const AdvertisementBar = ({
  doc
}: {
  doc: ContentRelationshipField<
    'advertisementBanner',
    string,
    AdvertisementBannerDocument['data']
  >
}) => {
  if (!isFilled.contentRelationship(doc) || !doc.data?.visibility) {
    return null
  }

  return (
    <Bounded className="mx-2">
      <Banner
        href={asLink(doc.data?.linkto, linkResolver) ?? ''}
        buttonText={doc.data?.buttontext}
        buttonClass="text-red-550"
      >
        <p className="whitespace-pre-wrap font-semibold">
          {doc.data.text} <i className="dot" />
        </p>
      </Banner>
    </Bounded>
  )
}

export default AdvertisementBar

import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { SliceZone } from '@prismicio/react'
import { AdvertTranslation, AdvertVisibilityType } from 'enums'

import { AdvertisementBar } from 'components/AdvertisementBar'
import { BoundedVariant } from 'components/Bounded'
import { components } from 'slices/'

import { getAdvertsPublic } from 'hooks/useAdvert/advert.service'

import { PageDocument } from 'types/prismic'
import { envUtil, setAxiosJwt } from 'utils'
import adUtils from 'utils/adUtil'
import { getFullPageByUID, getPrismicLangCode } from 'utils/prismic'
import { createClient } from 'env/prismicio'

const Home = ({ page }: { page: PageDocument<string> }) => {
  return (
    <>
      <AdvertisementBar doc={page.data.advertisementContent} />
      <SliceZone slices={page.data.slices} components={components as any} />
    </>
  )
}

export const getStaticProps: GetStaticProps = async ({
  locale: currentLocale,
  defaultLocale,
  previewData
}) => {
  const { adminJwt } = envUtil.getEnv()
  setAxiosJwt(adminJwt)

  const locale = currentLocale || defaultLocale || 'en'
  const client = createClient({ previewData })
  const pagePartsPromise = getFullPageByUID(
    'page',
    'home',
    {
      locale,
      fetchLinks: [
        'advertisementBanner.title',
        'advertisementBanner.text',
        'advertisementBanner.linkto',
        'advertisementBanner.buttontext',
        'advertisementBanner.visibility'
      ]
    },
    { previewData }
  )

  const communityPostsPromise = client.getAllByType('communitypost', {
    lang: getPrismicLangCode(locale),
    pageSize: 3,
    orderings: [
      { field: 'my.communitypost.publishDate', direction: 'desc' },
      { field: 'document.first_publication_date', direction: 'desc' }
    ]
  })

  const advertsPromise = getAdvertsPublic({
    languageCode: locale,
    limit: 18,
    statuses: [AdvertVisibilityType.Active],
    orderingColumn: 'dateCreated',
    order: 'desc'
  })

  const i18nPromise = serverSideTranslations(
    locale,
    [
      'home_adverts',
      'actions',
      'news_subscription',
      'inputs',
      'crm',
      'ad',
      'zod',
      'map',
      'filters',
      'data'
    ],
    null,
    adUtils.getAdvertTranslationsCodes(AdvertTranslation.EnglishLithuanian)
  )

  const [pageParts, i18n, adverts, communityPosts] = await Promise.all([
    pagePartsPromise,
    i18nPromise,
    advertsPromise,
    communityPostsPromise
  ])
  const { page, ...layoutParts } = pageParts

  const pageWithData = {
    ...page,
    data: {
      ...page?.data,
      slices: page?.data.slices.map((slice) => {
        if (slice.slice_type === 'advert_carousel') {
          return {
            ...slice,
            adverts: adverts
          }
        }
        if (slice.slice_type === 'community_section') {
          return {
            ...slice,
            communityPosts
          }
        }
        return slice
      })
    }
  }

  const [seo] = page?.data?.seo ?? []

  return {
    props: {
      ...i18n,
      page: pageWithData ?? page,
      communityPosts,
      seo: seo?.primary ?? null,
      internal: {
        layout: {
          boundedVariant: BoundedVariant.None,
          ...layoutParts
        }
      }
    },
    revalidate: 60 * 60 * 4 //4 hours
  }
}

export default Home
